.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-page-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  border-radius: 3rem; /* Custom border-radius */
}

.aspect-w-16 {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 ratio */
}

.aspect-w-16 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

 /* Mobile-only styles */
@media (max-width: 767px) {
  .mobile-padding {
    padding: 5px !important;
  }
}

/* Custom styles for the header */
.custom-header {
  padding-top: 24px; /* Default padding for larger screens */
  padding-bottom: 24px; /* Default padding for larger screens */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .custom-header {
    padding-top: 8px; /* Reduced padding for mobile screens */
    padding-bottom: 8px; /* Reduced padding for mobile screens */
  }
}
